import { useEffect } from "react";
import useStateValue from "frontend/state/value";
import { CanvasTemplate } from "shared/datamodel/schemas";
import { TeamPicker } from "./teamPicker";
import style from "./side-menu.module.css";
import { ProjectPicker } from "./projectPicker";
import { TeamMembers } from "./teamMembers";
import { Project } from "shared/datamodel/schemas/project";
import classNames from "classnames";
import {
  addToLocalStorageArray,
  getLocalStorageItem,
  isLocalStorageAvailable,
  setLocalStorageItem,
} from "frontend/utils/storage-utils";
import { Team } from "shared/datamodel/schemas/team";
import Skeleton from "react-loading-skeleton";
import { useTeam } from "frontend/hooks/use-team";

export function SideMenu({
  selectedTeam,
  setSelectedTeam,
  selectedProject,
  setSelectedProject,
  setShowTemplatesModal,
  createNewBoard,
  handleNoPermissionToCreate,
  onEditSeats,
}: {
  selectedTeam: Team | null;
  setSelectedTeam: (team: Team) => void;
  selectedProject: Project | null;
  setSelectedProject: (project: Project | null) => void;
  setShowTemplatesModal: (show: boolean) => void;
  createNewBoard: (template: CanvasTemplate, projectId?: number) => void;
  handleNoPermissionToCreate: () => void;
  onEditSeats: () => void;
}) {

  const teamsCanvasesIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4.59009L8.29185 1H8.70815L16 4.59009V5.40992L8.70815 9H8.29185L1 5.40992V4.59009ZM2.24887 5L8.5 8.07769L14.7511 5L8.5 1.92231L2.24887 5Z"
        fill="#113357"
        stroke="#11355E"
        strokeWidth="0.2"
        strokeLinecap="square"
        strokeLinejoin="bevel"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 9.39245L8.60981 13.1974H9.05702L16 9.72587V8.60783L8.83342 12.1911L1 8.27441V9.39245Z"
        fill="#113357"
      />
      <path
        d="M1 9.39245H0.9L0.955279 9.48189L1 9.39245ZM8.60981 13.1974L8.56509 13.2868L8.60981 13.2974V13.1974ZM9.05702 13.1974V13.2974L9.10175 13.2868L9.05702 13.1974ZM16 9.72587L16.0447 9.81531L16.1 9.72587H16ZM16 8.60783H16.1L15.9553 8.51839L16 8.60783ZM8.83342 12.1911L8.7887 12.2806H8.87814L8.83342 12.1911ZM1 8.27441L1.04472 8.18497L0.9 8.27441H1ZM0.955279 9.48189L8.56509 13.2868L8.65453 13.1079L1.04472 9.30301L0.955279 9.48189ZM8.60981 13.2974H9.05702V13.0974H8.60981V13.2974ZM9.10175 13.2868L16.0447 9.81531L15.9553 9.63642L9.0123 13.1079L9.10175 13.2868ZM16.1 9.72587V8.60783H15.9V9.72587H16.1ZM15.9553 8.51839L8.7887 12.1017L8.87814 12.2806L16.0447 8.69728L15.9553 8.51839ZM8.87814 12.1017L1.04472 8.18497L0.955279 8.36386L8.7887 12.2806L8.87814 12.1017ZM0.9 8.27441V9.39245H1.1V8.27441H0.9Z"
        fill="#11355E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 13.118L8.60981 16.9229H9.05702L16 13.4515V12.3334L8.83342 15.9167L1 12V13.118Z"
        fill="#113357"
      />
      <path
        d="M1 13.118H0.9L0.955279 13.2075L1 13.118ZM8.60981 16.9229L8.56509 17.0124L8.60981 17.0229V16.9229ZM9.05702 16.9229V17.0229L9.10175 17.0124L9.05702 16.9229ZM16 13.4515L16.0447 13.5409L16.1 13.4515H16ZM16 12.3334H16.1L15.9553 12.244L16 12.3334ZM8.83342 15.9167L8.7887 16.0062H8.87814L8.83342 15.9167ZM1 12L1.04472 11.9106L0.9 12H1ZM0.955279 13.2075L8.56509 17.0124L8.65453 16.8335L1.04472 13.0286L0.955279 13.2075ZM8.60981 17.0229H9.05702V16.8229H8.60981V17.0229ZM9.10175 17.0124L16.0447 13.5409L15.9553 13.362L9.0123 16.8335L9.10175 17.0124ZM16.1 13.4515V12.3334H15.9V13.4515H16.1ZM15.9553 12.244L8.7887 15.8273L8.87814 16.0062L16.0447 12.4229L15.9553 12.244ZM8.87814 15.8273L1.04472 11.9106L0.955279 12.0894L8.7887 16.0062L8.87814 15.8273ZM0.9 12V13.118H1.1V12H0.9Z"
        fill="#11355E"
      />
    </svg>
  );

  const templateLibraryIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H16V16H0V0ZM1.11628 1.11628V4.27918H14.8837V1.11628H1.11628ZM1.11628 14.8837V5.39546H4.27894L4.27894 14.8837H1.11628ZM5.39522 14.8837H14.8837V5.39546H5.39522L5.39522 14.8837Z"
        fill="#113357"
      />
    </svg>
  );

  const { getTeam } = useTeam();

  useEffect(() => {
    if (isLocalStorageAvailable()) {
      const teamsAndProjects = getLastSelectedTeamAndProject();
      const team = getTeam(teamsAndProjects.selectedTeam);
      setSelectedTeam(team);
      setSelectedProject(
        team?.projects?.find((p) => p?.id === teamsAndProjects.selectedProject) ?? null
      );
    }
  });

  const getLastSelectedTeamAndProject = () => {
    const teamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
    if (!teamsAndProjects) {
      const initValue = [{ selectedTeam: selectedTeam?.id, selectedProject: selectedProject?.id ?? null }];
      setLocalStorageItem("selectedTeamsAndProjects", initValue);
      return initValue;
    } else {
      return teamsAndProjects[teamsAndProjects.length - 1];
    }
  };

  const handleTeamsCanvasesClick = () => {
    if (!selectedTeam) return;
    const selectedTeamsAndProjects = getLocalStorageItem("selectedTeamsAndProjects");
    setLocalStorageItem(
      "selectedTeamsAndProjects",
      selectedTeamsAndProjects.filter((item: any) => item.selectedTeam !== selectedTeam.id)
    );
    addToLocalStorageArray("selectedTeamsAndProjects", {
      selectedTeam: selectedTeam.id,
      selectedProject: null,
    });
    setSelectedProject(null);
  };

  return (
    <div className={style.container}>
      <div className={style.startContainer}>
        {selectedTeam ? (
          <TeamPicker 
            selectedTeam={selectedTeam} 
            setSelectedTeam={setSelectedTeam} 
            onEditSeats={onEditSeats} 
            handleNoPermissionToCreate={handleNoPermissionToCreate}
          />
        ) : (
          <Skeleton count={1} containerClassName={style.teamPickerSkeleton} className={style.teamTitleSekeleton} />
        )}
        <div className={style.divider}></div>
        {selectedTeam ? (
          <div
            className={classNames(style.teamsCanvases, { [style.selected]: !selectedProject })}
            onClick={() => handleTeamsCanvasesClick()}
            data-testid="Team's canvases"
          >
            {teamsCanvasesIcon}
            <span>Team's canvases</span>
          </div>
        ) : (
          <Skeleton count={1} containerClassName={style.teamsCanvases} />
        )}
        <div
          className={style.templateLibrary}
          onClick={() => setShowTemplatesModal(true)}
          data-testid="Template library"
        >
          {templateLibraryIcon}
          <span>Template library</span>
        </div>
        <div className={style.divider}></div>
        {selectedTeam ? (
          <ProjectPicker
            selectedTeam={selectedTeam}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
            createNewBoard={createNewBoard}
            handleNoPermissionToCreate={handleNoPermissionToCreate}
            onEditSeats={onEditSeats}
          />
        ) : (
          <Skeleton count={3} containerClassName={style.projects} className={style.list} />
        )}
      </div>
      <div className={style.endContainer}>
        <div className={style.divider}></div>
        {selectedTeam && <TeamMembers selectedTeam={selectedTeam} onEditSeats={onEditSeats}/>}
      </div>
    </div>
  );
}

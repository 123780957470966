import useStateValue from "frontend/state/value";
import useOutsideRef from "frontend/utils/click-outside-handler";
import React, { useEffect, useRef, useState } from "react";
import style from "./invite-modal.module.css";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { Team } from "shared/datamodel/schemas/team";
import BulkPermission from "./collaboration/team/bulk-permission";
import { Permission } from "shared/datamodel/schemas";
import StyledButton from "frontend/ui-components/styled-button";
import { InviteMode } from "state-atoms";
import { useInvite } from "frontend/hooks/use-invite";
import { renderResponseStrip } from "frontend/ui-components/invite-emails";
import FormBubblesField from "frontend/ui-components/form-fields/form-bubbles-field";
import classNames from "classnames";
import { useTeam } from "frontend/hooks/use-team";
import { Project } from "shared/datamodel/schemas/project";
import NewPaidSeatsModal from "./new-paid-seats-modal";
import { useProject } from "frontend/hooks/use-project";

export default function InviteModal({
  name,
  team,
  project,
  onDismiss,
  onEditSeats,
  inviteMode,
  setCreated,
  source,
}: {
  name?: string;
  team?: Team;
  project?: Project;
  onDismiss: () => void;
  onEditSeats: () => void;
  inviteMode: InviteMode;
  setCreated?: (instance: Team | Project) => void;
  source: string;
}) {
  const [{ user }, dispatch] = useStateValue();

  const modalRef = useRef(null);

  const isFreePlan = !user || !user.planInfo || user.planInfo.is_free;
  const isTouchAccount = user?.planInfo?.source === "touch";
  const canManageSeats = !isFreePlan && !isTouchAccount && user.isAdmin && user.isSubscribedToCanvas;
  const [usersPermission, setUsersPermission] = useState<Permission>(Permission.editor);
  const [shouldRenderNewPaidSeatsModal, setShouldRenderNewPaidSeatsModal] = useState(false);
  const [isInstanceCreated, setIsInstanceCreated] = useState(false);
  const {
    inviteUsersResponse,
    emails,
    highlightInvalidEmails,
    submittingEmail,
    isLoadingInvite,
    changeEmails,
    onInvite,
  } = useInvite();

  const { handleCreateTeam } = useTeam();
  const { handleCreateProjectWithoutAddingCanvases } = useProject();

  const invalidCount = emails.filter((v) => !v.isValid).length;

  useOutsideRef(modalRef, onDismiss, { preventScroll: false });

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.INVITE_ACTION, "invite-to-account-modal-opened");
  }, []);

  useEffect(() => {
    if (inviteUsersResponse && inviteUsersResponse.addedSeats > 0) {
      !isTouchAccount && setShouldRenderNewPaidSeatsModal(true);
    }
  }, [inviteUsersResponse]);

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={onDismiss}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="currentColor"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function getName() {
    if (inviteMode === InviteMode.Create) {
      if (team) {
        return `"${name}" project`;
      } else {
        return `"${name}" team`;
      }
    }
    const teamName = team?.name ?? name;
    if (project) {
      return `"${project.name}" project`;
    } else if (team?.accountTeam) {
      const teamWordIndex = teamName?.lastIndexOf(" ");
      return `"${teamName?.substring(0, teamWordIndex)}" team`;
    } else {
      return `"${teamName}" team`;
    }
  }

  function renderEmailsInput() {
    return (
      <div style={{ padding: "25px 25px 0 25px" }}>
        <div className={style.fieldContainer}>
          {renderResponseStrip(inviteUsersResponse)}
          <FormBubblesField
            id={"emails"}
            autoFocus={true}
            onChange={(e) => changeEmails(e, source)}
            value={emails}
            placeholder="Enter email addresses"
            highlightInvalid={highlightInvalidEmails}
            customStyle={{ minHeight: "40px" }}
          />
          {invalidCount > 0 && (
            <div className={style.dynamicErrorMessage}>
              <img src="/images/error-info-icon.svg" />
              <span>
                {`you have entered ${invalidCount} invalid email addresses. Please check them from typos and enter new ones.`}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderFooter() {
    return (
      <div>
        <div className={classNames(style.footer, { [style.spaceBetween]: inviteMode === InviteMode.Create })}>
          {inviteMode === InviteMode.Create && name && (
            <span
              data-testid="Skip for now"
              onClick={() => {
                if (isInstanceCreated) {
                  onDismiss();
                } else {
                  team ? handleCreateProjectWithoutAddingCanvases(team, name, []) : handleCreateTeam(name, []);
                  onDismiss();
                }
              }}
              className={classNames(style.link, { [style.disabled]: isLoadingInvite })}
            >
              Skip for now
            </span>
          )}
          <StyledButton
            title={"Invite"}
            data-testid="new-invite-modal-button"
            loading={submittingEmail}
            enabled={emails.length > 0 && emails.every((i => i.isValid))}
            loadingTitle="Inviting..."
            onClick={() => {
              onInvite({
                invitePermission: usersPermission,
                inviteMode,
                team,
                project,
                dispatch,
                source,
                name,
                setCreated : (instance) => {
                  setIsInstanceCreated(true);
                  setCreated && setCreated(instance);
                },
                onDismiss: inviteMode === InviteMode.Create ? onDismiss : undefined,
              });
            }}
            customStyle={{ minWidth: "115px", width: "fit-content", zIndex: 1 }}
          />
        </div>
      </div>
    );
  }

  function renderInviteScreen() {
    return (
      <div className={style.container} ref={modalRef}>
        <div style={{ position: "relative" }}>{renderCloseIcon()}</div>
        <div className={style.header}>
          <span className={style.title}>{`Invite new people to ${getName()}`}</span>
          {canManageSeats && (
            <span className={style.manageSeats} onClick={onEditSeats} data-testid={"Manage seats"}>
              Manage seats
            </span>
          )}
        </div>
        {renderEmailsInput()}
        <BulkPermission usersPermission={usersPermission} setUsersPermission={setUsersPermission} />
        {renderFooter()}
      </div>
    );
  }

  function renderNewPaidSeatsModal() {
    return <NewPaidSeatsModal onDismiss={() => onDismiss()} inviteUsersResponse={inviteUsersResponse} />;
  }

  function renderModal() {
    if (shouldRenderNewPaidSeatsModal) {
      return renderNewPaidSeatsModal();
    } else {
      return renderInviteScreen();
    }
  }

  return renderModal();
}

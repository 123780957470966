import React, { useRef } from "react";
import collaborationStyle from "./collaboration-modal.module.css";
import style from "./new-collaboration-modal.module.css";
import manageStyle from "./manage-collaboration-modal.module.css";
import { Project } from "shared/datamodel/schemas/project";
import CollaborationHeader from "./collaboration-header";
import { Team } from "shared/datamodel/schemas/team";
import useOutsideRef from "frontend/utils/click-outside-handler";
import { CollaborationType } from "state-atoms";
import { useTeam } from "frontend/hooks/use-team";
import { useProject } from "frontend/hooks/use-project";

export default function DeleteCollaborationModal({
  type,
  subtitle,
  team,
  isSelected,
  project,
  onDismiss,
}: {
  type: CollaborationType;
  subtitle: string;
  team?: Team;
  isSelected?: boolean;
  project?: Project;
  onDismiss: () => void;
}) {
  const { handleDeleteTeam } = useTeam();
  const { handleDeleteProject } = useProject();

  const modalRef = useRef(null);

  useOutsideRef(modalRef, () => {
    onDismiss();
  });

  return (
    <div className={collaborationStyle.container} ref={modalRef}>
      <CollaborationHeader title={`Delete the ${type}?`} subtitle={subtitle} onDismiss={onDismiss} />
      <div className={style.leaveButtons}>
        <button
          className={manageStyle.manageButton}
          onClick={() => {
            onDismiss();
          }}
        >
          Cancel
        </button>
        <button
          className={manageStyle.deleteButton}
          onClick={() => {
            if (type === CollaborationType.Team && team) {
              handleDeleteTeam(team, isSelected);
            } else {
              project && handleDeleteProject(project);
            }
            onDismiss();
          }}
        >
          <img src={"/images/delete.svg"} />
          <span>{`Delete ${type}`}</span>
        </button>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";
import useStateValue from "frontend/state/value";
import { BoardsGrid, GridError } from "frontend/boards-grid/boards-grid";
import LoadedState from "frontend/state/loadedState";
import useAuthentication from "frontend/hooks/use-authentication";
import AppLoader from "frontend/loader/app-loader";
import MobileBlockError from "frontend/error/mobile-block-screen";
import Head from "next/head";
import tracking from "frontend/tracking";
import consts, { Plan } from "shared/consts";
import getStripePaymentStatus, { stringToPlan, updateAccountSeats, useCustomerPortal } from "frontend/billingUtils";
import { deleteCookie, readCookieValue } from "frontend/utils/cookie-utils";
import { createBoard } from "frontend/services/boardsService";
import { useRouter } from "next/router";
import { BoardPermission, BoardState, isPublicPermission } from "shared/datamodel/schemas/board";
import mixpanel from "mixpanel-browser";
import { addToLocalStorageArray } from "frontend/utils/storage-utils";
import Modal from "frontend/modal/modal";
import Promotion from "frontend/modals/promotions/promotion";
import { Action } from "frontend/state/actions";
import { loadAllInAppPromotions, loadPromotionById } from "frontend/utils/promotions-utils";
import { useAtom, useSetAtom } from "jotai";
import { invoicePreviewsAtom, plansDataAtom } from "state-atoms/billing-atoms";
import { getPlansData, getUser, retrieveUpcomingInvoicePreview } from "frontend/api";
import { isBusyAtom } from "state-atoms/general-atoms";
import OnboardingFlow from "../frontend/modals/onboarding";
import PaymentErrorModal from "frontend/billing/payment-error-modal";
import useShowOnboarding from "../frontend/hooks/use-onboarding";
import useUrlQueryParamValue from "frontend/hooks/use-url-query-param";
import BigModalContainer from "frontend/modals/big-modal-container";
import InviteView from "frontend/modals/onboarding/invite-view";
import posthog from "posthog-js";
import { experimentsAtom } from "state-atoms/experiments-atmos";
import { planToString } from "shared/util/billing";
import { MIN_SEATS } from "frontend/billing/payment-page";
import useSeatsCount from "frontend/hooks/use-seats-count";

const _JSXStyle = require("styled-jsx/style").default;

if (typeof global !== "undefined") {
  Object.assign(global, { _JSXStyle });
}

export default function Home({
  isMobile,
  promotionId,
  paymentIntentClientSecret,
  showInvite,
}: {
  isMobile: boolean;
  promotionId: string;
  paymentIntentClientSecret: string;
  showInvite: boolean;
}) {
  if (isMobile) {
    return <MobileBlockError />;
  }

  //atoms
  const setPlansDataAtom = useSetAtom(plansDataAtom);
  const setInvoicePreviewsAtom = useSetAtom(invoicePreviewsAtom);
  const [experimentsAtomValue,setExperimenstAtom] = useAtom(experimentsAtom);

  const [showGridError, setShowGridError] = useState<GridError | null>(null);
  const [showPaymentFailureModal, setShowPaymentFailureModal] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(showInvite);

  const [{ boardsState, user, boards, activePromotion }, dispatch] = useStateValue();
  const { isLoggedIn } = useAuthentication(true);
  const setBusy = useSetAtom(isBusyAtom);
  const showOnboarding = useShowOnboarding();
  const [paymentCompletedUrlParams, _] = useUrlQueryParamValue("setup_intent");
  const planInfoRef = useRef(user?.planInfo);
  const {userAccountCount, accountSubscribedCount, loadAccountSubscribedCount} = useSeatsCount(user);
  const router = useRouter();

  const didUpdateSeats = useRef(false); //bah react...
  const didReloadUser = useRef(false); //bah react...
  const enablePaymentAbTest = useRef<boolean>(false); //bah react...

  useEffect(() => {
    const enableAbTest = window && window.screen && window.screen.availWidth >= consts.MIN_WIDTH_TO_SHOW_PAYMENT_PAGE;
    enablePaymentAbTest.current = enableAbTest;
  }, []);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    async function initPlansData() {
      try {
        const data = await getPlansData();
        setPlansDataAtom(data);
      } catch {
        console.error("failed to get plans data");
      }
    }

    async function checkPaymentStatus() {
      const status = await getStripePaymentStatus(paymentIntentClientSecret);
      tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "payment-status", status);
      if (status === "failure") {
        setShowPaymentFailureModal(true);
      }
    }

    function initExpirements(retries:number, timeOutId?: NodeJS.Timeout) {
      const params = new URLSearchParams(window.location.search);
      const checkoutQueryParam = params.get('checkoutVariant');
      if(checkoutQueryParam && ['test','control'].includes(checkoutQueryParam)) {
        setExperimenstAtom([{name: consts.Experiment.CHECKOUT, value: checkoutQueryParam}])
        return;
      }
      const MAX_RETRIES = 2;
      const checkoutExperimentValue = posthog.getFeatureFlag(consts.Experiment.CHECKOUT);
      console.log('checkoutExperimentValue',checkoutExperimentValue);
      if(timeOutId) {
        clearTimeout(timeOutId);
      }
      if (typeof checkoutExperimentValue === 'string') {
        setExperimenstAtom([{name: consts.Experiment.CHECKOUT, value: checkoutExperimentValue}])
      } else {
        if(retries < MAX_RETRIES) { //for some reason, we don't alwats get the feature flag value on initial page load after signup
          console.log('initExpirements - feature flag was not found, retrying', retries)
          const id = setTimeout(() => {
            initExpirements(++retries, id)
          }, 500)
        } else {
          console.log('initExpirements - max retires excceded, fallback to default vaoue')
          setExperimenstAtom([{name: consts.Experiment.CHECKOUT, value: 'test'}]) //default value
        }
      }
    }

    tracking.initMixPanelClient();
    tracking.initPostHogClient();

    initPlansData();

    if(false && enablePaymentAbTest.current && (!experimentsAtomValue || experimentsAtomValue.length < 1)) {
      initExpirements(0);
    }

    if (paymentIntentClientSecret) {
      checkPaymentStatus();
    }
  }, [isLoggedIn]);

  async function updateSeats(seatsCount: number) {
    try {
      await updateAccountSeats(seatsCount);
      //reload users data after updating
      let user = await getUser();
      dispatch({ type: Action.UpdatedUser, payload: user });
    } catch (e) {
      console.error("couldn't update number of seats because of an error", e);
    }
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    //Since we don't currently have a dedicated cron job that periodically checks the status of the users in the account (users added, accepted invite, removed etc.)
    //we check it here on page load. If we are on the free tier, make sure the account's subscription is synced with the amount of users in the account
    if (user.planInfo?.is_free && !didUpdateSeats.current) {
      didUpdateSeats.current = true;
      updateSeats(userAccountCount);
    } else if (!user.planInfo?.is_free) {
      loadAccountSubscribedCount();
    }
    if (mixpanel) {
      const numberOfUsers = user.planInfo?.is_free ? userAccountCount : accountSubscribedCount;
      mixpanel.register({ numberOfUsers: numberOfUsers });
    }
  }, [user]);

  useEffect(() => {
    if (!user?.planInfo?.is_free && !didUpdateSeats.current && accountSubscribedCount > 3) {
      didUpdateSeats.current = true;
      updateSeats(accountSubscribedCount);
    }
  },[accountSubscribedCount])

  useEffect(() => {
    async function checkForPromotions() {
      if (promotionId) {
        loadPromotionById(dispatch, promotionId);
      } else {
        loadAllInAppPromotions(dispatch);
      }
    }

    async function createTemplateBoard(templateId: string) {
      try {
        const boardState = BoardState.templateNotSetUp;
        const name = "New Canvas";
        return await createBoard(name, boardState, templateId, null, null, dispatch);
      } catch (e: any) {
        if (e.response.status === 406) {
          setShowGridError({ type: "limitReached", data: e.response.data.maxBoardsAllowed });
          setBusy(false);
        }
      }
      return null;
    }

    async function initTemplate() {
      const templateId = readCookieValue(consts.COOKIE_NAMES.BOARD_TEMPLATE);
      deleteCookie(consts.COOKIE_NAMES.BOARD_TEMPLATE, "/", ".workcanvas.com");
      if (templateId) {
        setBusy(true);
        const board = await createTemplateBoard(templateId);
        setBusy(false);
        if (board) {
          router.push(`/d/${board.documentId}`);
        }
      }
    }

    //A workaround to detect hard-signups: If x minutes has passes since the user was created until he landed in the app, we consider it a new signup
    if (user && user.createdAt) {
      mixpanel.identify(user.id.toString());
      mixpanel.register(user);
      mixpanel.people.set(user);
      posthog.identify(user.id.toString(), user);

      if (
        user.account?.id === "5" ||
        process.env.NEXT_ENV !== "production" ||
        (window as any)._DATADOG_SYNTHETICS_BROWSER !== undefined
      ) {
        mixpanel.opt_out_tracking(); //don't send data for monday account users or in dev or for datadog bots
      }
      initTemplate();
    }

   /* if (user?.planInfo && !activePromotion) {
      checkForPromotions();
    }*/
  }, [user]);

  useEffect(() => {
    if (boards && boards.length > 0) {
      const canvasesData = {
        totalCanvases: boards.length,
        totalOwnedCanvases: boards.filter((i) => i.isOwner).length,
        totalPublicCanvases: boards.filter((i) => isPublicPermission(i.permission)).length,
        totalPrivateCanvases: boards.filter((i) => i.permission === BoardPermission.private).length,
      };
      mixpanel.register(canvasesData);
    }
  }, [boards]);

  //Ugly hack alert:since now the server works really fast in production, after a succesfull checkout the client reloads very quickly and stripe's webhook
  //doesn't make it before the client loads, and the user doesn't see the change (only after another refresh.)
  //Here we are detecting if the url indicates on a succefull tansaction, and if so, we are reloading the user after a few seconds
  useEffect(() => {
    async function reloadUser() {
      //reload users data after updating
      try {
        let user = await getUser();
        dispatch({ type: Action.UpdatedUser, payload: user });
      } catch {
        console.error("couldn't reload user after a successful transaction");
      }
    }
    if (paymentCompletedUrlParams && !didReloadUser.current) {
      didReloadUser.current = true;
      setTimeout(() => {
        console.log("reloading user");
        reloadUser();
      }, 4000);
    }
  }, [paymentCompletedUrlParams]);

  useEffect(() => {
    async function previewInvoices() {
      if(user?.planInfo?.seats_count) {
        const paidPlans = Object.values(Plan)
        .filter((i) => isNaN(parseInt(i as string)))
        .filter((i) => i != planToString(Plan.basic)?.toLowerCase());
      let invoicePromises: Promise<any>[] = [];
      for (const plan of paidPlans) {
        for (const period of ["month", "year"]) {
          const invoicePromise = retrieveUpcomingInvoicePreview(
            stringToPlan(plan as string)!,
            Math.max(user?.planInfo?.seats_count, MIN_SEATS),
            period as any
          );
          invoicePromises.push(invoicePromise);
        }
      }
      const invoices = await Promise.all(invoicePromises);
      setInvoicePreviewsAtom(invoices);
      }
    }
    if (user?.planInfo?.is_free) {
      previewInvoices();
    }
  }, [user?.planInfo?.seats_count])

  function renderBoardsState() {
    switch (boardsState) {
      case LoadedState.initial:
      case LoadedState.loading:
        return <AppLoader />;
      case LoadedState.finished:
        return (
          <React.Fragment>
            {!showOnboarding && !showInviteModal && <BoardsGrid showGridError={showGridError} />}
            {!showOnboarding && showInviteModal && (
              <BigModalContainer>
                <InviteView onCompleted={() => setShowInviteModal(false)} />
              </BigModalContainer>
            )}
            {showOnboarding && <OnboardingFlow />}
            {activePromotion && !activePromotion?.isDismissed && (
              <Modal dimBackground={true}>
                <Promotion
                  id={activePromotion.id}
                  discount={activePromotion.discount}
                  duration={activePromotion.duration}
                  code={activePromotion.couponCode}
                  validUntill={activePromotion.validUntill}
                  type={activePromotion.type}
                  usersCount={user?.planInfo?.is_free ? userAccountCount : accountSubscribedCount}
                  onClose={() => {
                    addToLocalStorageArray(consts.LOCAL_STORAGE_KEYS.PROMOTIONS_DISMISSED, activePromotion.id);
                    dispatch({ type: Action.PromotionLoaded, payload: { ...activePromotion, isDismissed: true } });
                  }}
                  plan={Plan.pro}
                  interval={"month"}
                />
              </Modal>
            )}
          </React.Fragment>
        );
      case LoadedState.failed:
        return <span>Failed!</span>;
    }
  }

  function render() {
    if (isLoggedIn) {
      return renderBoardsState();
    }
    return <AppLoader />;
  }

  return (
    <div>
      <Head>
        <title>WorkCanvas.com</title>
      </Head>
      {render()}
      {showPaymentFailureModal && (
        <Modal dimBackground={true}>
          <PaymentErrorModal
            onClick={() => useCustomerPortal({ type: "update-payment" })}
            onDismiss={() => setShowPaymentFailureModal(false)}
          />
        </Modal>
      )}
    </div>
  );
}

export function getServerSideProps(context: any) {
  const UA = context.req.headers["user-agent"];
  const { promotionId, payment_intent_client_secret, show_invite } = context.query;

  let isMobile;
  if (UA) {
    isMobile = Boolean(
      UA.match(/\b(BlackBerry|webOS|iPhone|IEMobile)\b/i) || UA.match(/\b(Android|Windows Phone|iPad|iPod)\b/i)
    );
  }
  return {
    props: {
      isMobile,
      promotionId: promotionId || null,
      paymentIntentClientSecret: payment_intent_client_secret || null,
      showInvite: show_invite || null,
    },
  };
}

import { z } from "zod";
import { accountSchema } from "./account";
import { billingIntervalSchema } from "./billing";
import { TipName } from "./tip";

export enum Permission {
  editor,
  viewer,
}

//TODO: If we will have more features schemas, we should move it to a dedicated file
export const templatesFeatureSchema = z.union([z.literal("all"), z.literal("basic")]);

export const waterMarkFeatureSchema = z.object({
  withWaterMark: z.boolean(),
  types: z.array(z.union([z.literal("jpg"), z.literal("png")])),
});

export const videoCallsFeatureSchema = z.object({
  enabled: z.boolean(),
});

export type WaterMarkFeatureConfig = z.infer<typeof waterMarkFeatureSchema>;

export type TemplatesFeatureConfig = z.infer<typeof templatesFeatureSchema>;

export type VideoCallsFeatureConfig = z.infer<typeof videoCallsFeatureSchema>;

export const planFeatures = z.object({
  name: z.string(),
  feature_value: z.string().nullish(),
});

export type PlanFeature = z.infer<typeof planFeatures>;

export const planSchema = z.object({
  name: z.string(),
  plan_id: z.number().optional(),
  interval: billingIntervalSchema.nullish(),
  is_free: z.boolean(),
  invoice_paid: z.boolean(),
  enc_features: z.string().optional(),
  features: z.array(planFeatures).optional(),
  seats_count: z.number(),
  stripe_user_id: z.string(),
  stripe_subscription_id: z.string().nullish(),
  trial_end_date: z.date().nullish(),
  trial_start_date: z.date().nullish(),
  plan_start_date: z.date().nullish(),
  plan_cancelled_date: z.date().nullish(),
  source: z.string().nullish(),
  is_downgraded: z.boolean().default(false).optional(),
  was_subscription_cancelled: z.boolean().default(false).optional(),
  is_active: z.boolean().optional(),
});

export function planInfoFromDBModel(obj: any) {
  if (obj) {
    let trialEndDate,
      trialStartDate,
      planStartDate,
      planCancelledDate: Date | null = null;
    if (obj.trial_end_date) {
      trialEndDate = new Date(obj.trial_end_date);
    }
    if (obj.trial_start_date) {
      trialStartDate = new Date(obj.trial_start_date);
    }
    if (obj.plan_start_date) {
      planStartDate = new Date(obj.plan_start_date);
    }
    if (obj.plan_cancelled_date) {
      planCancelledDate = new Date(obj.plan_cancelled_date);
    }
    return planSchema.parse({
      ...obj,
      trial_end_date: trialEndDate,
      trial_start_date: trialStartDate,
      plan_start_date: planStartDate,
      plan_cancelled_date: planCancelledDate,
    });
  }
  return null;
}

export type PlanInfo = z.infer<typeof planSchema>;

export const userSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  thumbnail: z.string(),
  photo: z.string(),
  pending: z.boolean(),
  account: accountSchema.nullish(),
  planInfo: planSchema.nullish(),
  createdAt: z.string().nullish(),
  isNewSignup: z.boolean().nullish(),
  canvasCreatedDate: z.string().nullish(),
  repsToken: z.string().nullish(),
  serialNumber: z.number().optional(),
  tipsSeen: z.array(z.nativeEnum(TipName)).default([]),
  announcement: z.object({ id: z.number(), data: z.any() }).optional(),
  isGuest: z.boolean().default(false),
  isAdmin: z.boolean().default(false),
  permission: z.nativeEnum(Permission).default(Permission.editor),
  isSubscribedToCanvas: z.boolean().default(true),
  canInvite: z.boolean().default(false),
});

export const invitedUserSchema = z.object({
  id: z.number(),
  email: z.string(),
  token: z.string(),
  expiration: z.string(),
  permission: z.nativeEnum(Permission).default(Permission.editor),
});

export type InvitedUser = z.infer<typeof invitedUserSchema>;

function safeParseTips(tips: string[] | undefined | null) {
  if (!tips) {
    return [];
  }
  const parsed = new Array<TipName>();
  tips.forEach((tipStr) => {
    const tip = z.nativeEnum(TipName).safeParse(tipStr);
    if (tip.success) {
      parsed.push(tip.data);
    }
  });
  return parsed;
}

export const mondayUserRoleSchema = z.object({
  isAdmin: z.boolean().default(false),
  isGuest: z.boolean().default(false),
  isSubscribedToCanvas: z.boolean().default(true),
});

export type MondayUserRole = z.infer<typeof mondayUserRoleSchema>;

export function userFromDBModel(obj: any): User | null {
  if (obj) {
    const planInfo = planInfoFromDBModel(obj.planInfo);
    const canInvite = obj.is_admin || (obj.permission !== Permission.viewer && !obj.is_guest);
    try {
      const user = userSchema.parse({
        id: obj.id.toString(),
        name: obj.name,
        email: obj.email,
        thumbnail: obj.photo_thumb,
        photo: obj.photo_original,
        pending: obj.is_pending,
        account: obj.account,
        planInfo,
        createdAt: obj.created_at,
        isNewSignup: obj.isNewSignup,
        canvasCreatedDate: obj.canvasCreatedDate,
        repsToken: obj.repsToken,
        serialNumber: obj.serialNumber,
        tipsSeen: safeParseTips(z.array(z.string()).optional().parse(obj.tipsSeen)),
        announcement: obj.announcement,
        isGuest: obj.is_guest ?? false,
        isAdmin: obj.is_admin ?? false,
        permission: obj.permission ?? Permission.editor,
        isSubscribedToCanvas: obj.isSubscribedToCanvas,
        canInvite,
      });
      return user;
    } catch (error) {
      console.error("Error parsing user from DB model", error);
    }
  }
  return null;
}

export type User = z.infer<typeof userSchema>;

export const usersOnPage = 100;

export interface UsersCache {
  [key: string]: {users: User[], page: number, hasMore: boolean, failed: boolean}; // key is type-id (e.g. team-1)
}
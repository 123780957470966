import { Team } from "shared/datamodel/schemas/team";
import style from "./side-menu.module.css";
import Skeleton from "react-loading-skeleton";
import Modal from "frontend/modal/modal";
import ManageTeamMembers from "frontend/modals/collaboration/team/manage-team-members";
import { useEffect, useState } from "react";
import useStateValue from "frontend/state/value";
import { InviteMode } from "state-atoms";
import useAbility from "frontend/hooks/use-ability";
import { InstanceType } from "shared/datamodel/schemas/ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import useUsers from "frontend/hooks/use-users";
import InviteModal from "frontend/modals/invite-modal";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import useSeatsCount from "frontend/hooks/use-seats-count";

export function TeamMembers({ selectedTeam, onEditSeats }: { selectedTeam: Team; onEditSeats: () => void }) {
  const [{ user }] = useStateValue();
  const { users, isLoading: isLoadingUsers, loadUsers, reloadUsers } = useUsers(InstanceType.Team, selectedTeam?.id);
  const [showManageTeamModal, setShowManageTeamModal] = useState(false);
  const [showInviteTeamModal, setShowInviteTeamModal] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState<Boolean>(false);
  const { canPerformAnyAction } = useAbility();
  const { userAccountCount } = useSeatsCount(user);
  const [teammatesCount, setTeammatesCount] = useState(0);

  const plusIcon = (
    <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.49658 1V8" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M8.18652 4.5L1.00009 4.5" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round" />
    </svg>
  );

  async function loadUsersIfNeeded() {
    try {
      const shouldReloadUsers = selectedTeam?.accountTeam ? false : selectedTeam?.userTeamPermissions.length !== users?.length;
    
      if (users?.length === 0) {
        await loadUsers();
      } else if (shouldReloadUsers && !isLoadingUsers) {
        await reloadUsers();
      }
    } catch (error) {
      console.error("An error occurred while loading or reloading users:", error);
    }
  }

  useEffect(() => {
    if (selectedTeam?.accountTeam) {
      setTeammatesCount(userAccountCount);
    } else {
      setTeammatesCount(selectedTeam?.userTeamPermissions?.length);
    }
  }, [selectedTeam?.accountTeam, selectedTeam?.id, users.length, userAccountCount]);

  useEffect(() => {
    loadUsersIfNeeded();
   },[selectedTeam.id, isLoadingUsers, selectedTeam?.userTeamPermissions.length]);

  useEffect(() => {
    if (selectedTeam?.accountTeam) {
      setTeammatesCount(userAccountCount);
    } else {
      setTeammatesCount(selectedTeam?.userTeamPermissions?.length);
    }
  }, [selectedTeam?.accountTeam, selectedTeam?.id, users.length, userAccountCount]);

  const canInvite = selectedTeam?.isOwner || (selectedTeam?.accountTeam && user?.canInvite);

  function handlePlusIconClick() {
    const shouldShoWRestrictedModal = user && !canPerformAnyAction(user, selectedTeam, InstanceType.Team);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.ADMIN_ACTION,
      "invite_to_team_button_clicked",
      "invite_type",
      "account",
    );
    if (shouldShoWRestrictedModal) {
      setShowRestrictedModal(true);
    } else {
      setShowInviteTeamModal(true);
    }
  }

  function renderManageTeamButton() {
    if (isLoadingUsers || userAccountCount === 0) {
      return <Skeleton count={1} className={style.teammateSkeleton} containerClassName={style.teammatesSkeleton} />;
    } else {
      return teammatesCount > 4 ? (
        <div
          className={style.more}
          onClick={() => {
            setShowManageTeamModal(true);
          }}
        >
          <img className={style.teammatesIcon} src="/images/teammates.svg" />
          <div className={style.innerContainer}>
            <span className={style.teammatesCount}>{`${teammatesCount - 4} more`}</span>
          </div>
        </div>
      ) : (
        <div
          className={style.manageTeam}
          onClick={() => {
            setShowManageTeamModal(true);
          }}
        >
          <img className={style.teammatesIcon} src="/images/teammates-blue.svg" />
          <span
            className={style.teammatesCount}
            style={{ color: "#1973FF" }}
            onClick={() => {
              setShowManageTeamModal(true);
            }}
            data-testid={selectedTeam?.isOwner ? `Manage Members` : `Team Members`}
          >
            {selectedTeam?.isOwner ? `Manage Members` : `Team Members`}
          </span>
        </div>
      );
    }
  }

  return (
    <>
      <div className={style.teammatesTitle}>
        <span>Teammates</span>
        {canInvite && (
          <span className={style.plusIcon} onClick={() => handlePlusIconClick()} data-testid={`add team member`}>
            {plusIcon}
          </span>
        )}
      </div>
      <div className={style.teammates}>
        {isLoadingUsers || userAccountCount === 0 ? (
          <Skeleton count={4} className={style.teammateSkeleton} containerClassName={style.teammatesSkeleton} />
        ) : (
          users &&
          users.slice(0, 4).map((user) => (
            <div key={user.id} className={style.teammatesPreview}>
              <img src={user.thumbnail} />
              <div className={style.innerContainer}>
                <span className={style.name}>{user.name}</span>
              </div>
            </div>
          ))
        )}
      </div>
      {renderManageTeamButton()}
        {showManageTeamModal && (
          <Modal dimBackground={true}>
            <ManageTeamMembers
              onDismiss={() => {
                setShowManageTeamModal(false);
              }}
              team={selectedTeam}
              onEditSeats={onEditSeats}
            />
          </Modal>
        )}
        {showInviteTeamModal && (
          <Modal dimBackground={true}>
            <InviteModal
              team={selectedTeam}
              onDismiss={() => setShowInviteTeamModal(false)}
              inviteMode={InviteMode.Edit}
              onEditSeats={onEditSeats}
              source="edit-team-members"
            />
          </Modal>
        )}
        {showRestrictedModal && (
          <RestrictedUserModal
            trackingEvent={"home"}
            onDismiss={() => setShowRestrictedModal(false)}
          />
        )}
    </>
  );
}
